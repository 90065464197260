
//* Components */
import { defineComponent } from "vue";
//* Packages */
import http from "../../../utils/http-common";
//* Models */
import IDrink from "../../../interfaces/IDrink";
import { toast } from 'vue3-toastify';
import LoadingSpinner from '@/components/partials/custom-fields/LoadingSpinner.vue';

export default defineComponent({
    name: "DrinkSelector",
    components: {LoadingSpinner},
    data() {
        return {
            loading: true as boolean,
            drinks: null as unknown as number,
            drinkList: [] as IDrink[],
            selectedDrinks: [] as IDrink[],
            openDrinkCart: false as boolean,
            totalCosts: 0 as number,
        };
    },
    methods: {
        getDrinks() {
            http.get(`non_extra_drinks`, {
                params: {
                    is_active: true
                }
            }).then((res) => {
                this.drinkList = res.data;
                this.loading = false;
            })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                });
        },
        addDrink(drink: IDrink) {
            if (this.selectedDrinks.length < this.drinks) {
                this.selectedDrinks.push(drink);
                this.$emit("onSelectedDrinkChange", this.selectedDrinks);
                this.animateIndicator();
            }
            else {
                toast(this.$t('DRINK_SELECTOR_NO_MORE_DRINKS'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })
            }
        },
        removeDrink(drink: IDrink) {
            const indexToRemove = this.selectedDrinks.findIndex(
                (item) => item.id === drink.id
            );

            if (indexToRemove !== -1) {
                this.selectedDrinks.splice(indexToRemove, 1);
                this.$emit("onSelectedDrinkChange", this.selectedDrinks);
                this.animateIndicator();
            }
        },
        currentDrinkOverview(): { drink: string, amount: number, price: number }[] {
            const ids: { [key: string]: number } = {};

            this.selectedDrinks.forEach((drink: { id: number }) => {
                const drinkId = drink.id;

                if (ids[drinkId]) {
                    ids[drinkId]++;
                } else {
                    ids[drinkId] = 1;
                }
            });

            const result = Object.keys(ids).map((drinkId) => {
                const count = ids[drinkId];
                const drink = this.selectedDrinks.find(
                    (arr) => arr.id === parseInt(drinkId)
                );
                const drinkName = drink?.name || "Unknown";
                const drinkPrice = drink?.price || 0
                return { drink: drinkName, amount: count, price: drinkPrice };
            });

            return result;
        },
        animateIndicator(): void {
            const indicator = document.querySelector(
                "#drink-indicator"
            ) as HTMLElement;

            if (indicator) {
                indicator.classList.remove("grow-shrink");
                void indicator.offsetWidth;
                indicator.classList.add("grow-shrink");
            }
        },
        countDrink(id: number): number {
            return this.selectedDrinks.filter((drink) => drink.id === id).length;
        },
        nextStep() {
            if(!this.amountOfDrinks) {
                return;
            }
            if(this.selectedDrinks.length != this.amountOfDrinks && (this.amountOfDrinks - this.selectedDrinks.length) > 0) {
                const difference = this.amountOfDrinks - this.selectedDrinks.length;
                toast(this.$t(difference !== 1 ? 'ERROR_ARRANGEMENT_SELECTOR_ALL_DRINKS' : 'ERROR_ARRANGEMENT_SELECTOR_ALL_DRINK', {amount: difference}), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })

                return;
            }
            if(this.selectedDrinks.length != this.amountOfDrinks && (this.amountOfDrinks - this.selectedDrinks.length) < 0) {
                toast(this.$t('PAYMENT_DETAILS_TO_MUCH_DRINKS'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })

                return;
            }
            this.openDrinkCart = false;
            this.$emit('setDrinks', this.selectedDrinks);
        },
        handleClickOutside(event: Event) {
            if (!this.$el.contains(event.target)) {
                this.openDrinkCart = false;
            }
        },
        prevStep(): void {
            this.openDrinkCart = false;
            this.$emit('prevStep')
        },
        toggleCart(): void {
            this.openDrinkCart = !this.openDrinkCart;
        },
        formattedPrice(price: number): string {
            let symbols = [
                '€',
                '$'
            ]

            const item = localStorage.getItem('currency_symbol');
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price.toFixed(2)}`;
                } else {
                    return `${price.toFixed(2)} ${item}`;
                }
            }
            return `${price}`;
        },
    },
    props: {
        amountOfDrinks: Number,
    },
    watch: {
        amountOfDrinks: {
            handler(drinks) {
                if (drinks) {
                    this.drinks = drinks;
                    this.getDrinks();
                }
            },
        },
    },
});
