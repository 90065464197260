
//* Components */
import { defineComponent } from "vue";
//* Packages */
import http from "../../../utils/http-common";
import { toast } from 'vue3-toastify';
import ISetting from '@/interfaces/ISetting';

//* Models */

export default defineComponent({
    name: "CapacityDropdown",
    components: {},
    data() {
        return {
            selectedOption: null as unknown as number,
            showOptions: false as boolean,
            options: [] as Array<number>,
            priceSettings: [] as ISetting[]
        };
    },
    props: {
        category: String,
        onlyDropDown: Boolean,
        zIndex: Number,
        backgroundColor: {
            type: String,
            default: "",
        },
    },
    methods: {
        toggleDropdown() {
            if (this.category) {
                this.showOptions = !this.showOptions;
            }
        },
        selectOption(option: number) {
            this.selectedOption = option;
            this.$emit("selected", option);
        },
        getPrices(): void {
            http.get(`person-price-settings`).then((res) => {
                this.priceSettings = res.data;
            })
        },
        handleClickOutside(event: Event) {
            if (!this.$el.contains(event.target)) {
                this.showOptions = false;
            }
        },
        formattedPrice(index: number): string {
            const setting = this.priceSettings.find((setting: ISetting) => setting.key === 'PERSON_PRICE_SETTINGS_' + index)
            if(setting && isNaN(parseFloat(String(setting.value)))) {
                return '0.00'
            }

            let price = parseFloat(String(setting?.value));
            // console.log(price)
            // console.log(typeof price)
            let symbols = [
                '€',
                '$'
            ]

            const item = localStorage.getItem('currency_symbol');
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price?.toFixed(2)}`;
                } else {
                    return `${price?.toFixed(2)} ${item}`;
                }
            }
            return `${price}`;
        },
        getCapacity(category: string): void {
            if (!category || category === "") {
                return;
            }
            http.get(`get_boat_capacity/${category}`).then((res) => {
                for (let i = 2; i < res.data.capacity + 1; i++) {
                    this.options.push(i);
                }
            })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                });
        },
    },
    watch: {
        category: {
            handler(category) {
                this.selectedOption = 0
                this.options        = [];
                this.getCapacity(category);
            },
            immediate: true,
        },
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
        this.getPrices();
    },
});
