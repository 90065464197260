

/** Components */
import { defineComponent } from "vue";
import FrontendDatePicker from "../partials/custom-fields/FrontendDatePicker.vue";
import ProgressBar from "../partials/custom-fields/ProgressBar.vue";
import BookingDetails from "../frontend/booking-process/BookingDetails.vue";
import ArrangementSelector from '@/components/backend/PartialViews/ArrangementSelector.vue';
import DrinkSelector from '@/components/backend/PartialViews/DrinkSelector.vue';

/** Packages */
/** Models */
import ITimeSlot from "../../interfaces/ITimeSlot";
import ICustomer from '@/interfaces/ICustomer';
import IArrangement from '@/interfaces/IArrangement';
import IDrink from '@/interfaces/IDrink';
import IDish from '@/interfaces/IDish';
import ExtraSelector from '@/components/backend/PartialViews/ExtraSelector.vue';
import PaymentDetails from '@/components/frontend/booking-process/PaymentDetails.vue';
import LanguageDropdown from '@/components/partials/custom-fields/LanguageDropdown.vue';
import http from '@/utils/http-common';


export default defineComponent({
    name: "BookingView",
    // eslint-disable-next-line vue/no-unused-components
    components: {LanguageDropdown, PaymentDetails, ExtraSelector, DrinkSelector, ArrangementSelector, FrontendDatePicker, ProgressBar, BookingDetails},
    data() {
        return {
            selectedDate: '' as string,
            selectedSlot: {} as ITimeSlot,
            customer: {} as ICustomer,
            selectedBoatCategory: '' as string,
            guests: 0 as number,
            currentStep: 0 as number,
            selectedArrangements: [] as IArrangement[],
            selectedDrinks: [] as IDrink[],
            extraDrinks: [] as IDrink[],
            extraDishes: [] as IDish[],
            amountOfDrinks: null as unknown as number,
            region: null as null | string
        }
    },
    methods: {
        startReservation(slot: ITimeSlot, date: string): void {
            this.selectedDate = date;
            this.selectedSlot = slot;
            this.currentStep++;
        },
        setDetails(customer: ICustomer, selectedBoatCategory: string, guests: number): void {
            this.customer             = customer;
            this.selectedBoatCategory = selectedBoatCategory;
            this.guests               = guests;
            this.currentStep++;
        },
        setArrangements(arrangements: IArrangement[]): void {
            this.selectedArrangements = arrangements;
            this.calculateAmountOfDrinks();
            this.currentStep++;
        },
        setDrinks(drinks: IDrink[]): void {
            this.selectedDrinks = drinks;
            this.currentStep++;
        },
        calculateAmountOfDrinks(): void {
            const amount: number = this.selectedArrangements.reduce(
                (sum: number, arrangement: IArrangement) => {
                    return sum + (arrangement.drink_amount || 0);
                },
                0
            );

            if (this.selectedArrangements.length === this.guests) {
                this.amountOfDrinks = amount;
            }
        },
        setStep(index: number): void {
            this.currentStep = index;
        },
        setExtras(drinks: IDrink[], dishes: IDish[]): void {
            this.extraDrinks = drinks;
            this.extraDishes = dishes;
            this.currentStep++;

        },
        nextStep(): void {
            this.currentStep++;
        },
        prevStep(): void {
            this.currentStep--;
        },
        setCategory(category: string): void {
            this.selectedBoatCategory = category;
        },
        setCapacity(guests: number ): void {
            this.guests = guests;
        },
        arrangementChange(arrangements: IArrangement[]) {
            this.selectedArrangements = arrangements;
            this.calculateAmountOfDrinks();
        }
    },
    mounted() {
        http.get('settings/key/REGION_SETTINGS').then((res) => {
            this.region = res.data.value;
        })

        http.get('settings/key/CURRENCY_SYMBOL_SETTINGS').then((res) => {
            localStorage.setItem('currency_symbol', res.data.value);
        })
    }
});
