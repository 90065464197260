
import { defineComponent } from 'vue'
import IDrink from '@/interfaces/IDrink';
import IDish from '@/interfaces/IDish';
import http from '@/utils/http-common';
import { toast } from 'vue3-toastify';

export default defineComponent({
    name: "ExtraSelector",
    data() {
        return {
            extraDrinksList: [] as IDrink[],
            extraDishesList: [] as IDish[],
            selectedExtraDrinks: [] as IDrink[],
            selectedExtraDishes: [] as IDish[],
            openExtraCart: false as boolean,
            totalCosts: 0 as number,
        };
    },
    methods: {
        getDrinks() {
            http.get(`drinks`, { params: { pagination: false } }).then((res) => {
                this.extraDrinksList = res.data;
            })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                });
        },
        getArrangements() {
            http.get(`dishes`, { params: { pagination: false } }).then((res) => {
                this.extraDishesList = res.data;
            })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                });
        },
        countDrink(id: number): number {
            return this.selectedExtraDrinks.filter((drink) => drink.id === id).length;
        },
        countDish(id: number): number {
            return this.selectedExtraDishes.filter((dish) => dish.id === id).length;
        },
        addDrink(drink: IDrink) {
            this.selectedExtraDrinks.push(drink);
            this.animateIndicator();
            this.calculateTotalCost(drink.price, 'increment');
        },
        removeDrink(drink: IDrink) {
            const indexToRemove = this.selectedExtraDrinks.findIndex(
                (item) => item.id === drink.id
            );

            if (indexToRemove !== -1) {
                this.selectedExtraDrinks.splice(indexToRemove, 1);
                this.animateIndicator();
                this.calculateTotalCost(drink.price, 'subtract');
            }
        },
        addDish(dish: IDish) {
            this.selectedExtraDishes.push(dish);
            this.animateIndicator();
            this.calculateTotalCost(dish.price, 'increment');
        },
        removeDish(dish: IDish) {
            const indexToRemove = this.selectedExtraDishes.findIndex(
                (item) => item.id === dish.id
            );

            if (indexToRemove !== -1) {
                this.selectedExtraDishes.splice(indexToRemove, 1);
                this.animateIndicator();
                this.calculateTotalCost(dish.price, 'subtract');
            }
        },
        animateIndicator(): void {
            const indicator = document.querySelector(
                "#extra-indicator"
            ) as HTMLElement;

            if (indicator) {
                indicator.classList.remove("grow-shrink");
                void indicator.offsetWidth;
                indicator.classList.add("grow-shrink");
            }
        },
        currentDrinkOverview(): { drink: string, amount: number, price: number }[] {
            const ids: { [key: string]: number } = {};

            this.selectedExtraDrinks.forEach((drink: { id: number }) => {
                const drinkId = drink.id;

                if (ids[drinkId]) {
                    ids[drinkId]++;
                } else {
                    ids[drinkId] = 1;
                }
            });

            const result = Object.keys(ids).map((drinkId) => {
                const count = ids[drinkId];
                const drink = this.selectedExtraDrinks.find(
                    (arr) => arr.id === parseInt(drinkId)
                );
                const drinkName = drink?.name || "Unknown";
                const drinkPrice = drink?.price || 0
                return { drink: drinkName, amount: count, price: drinkPrice};
            });

            return result;
        },
        currentDishesOverview(): { dish: string, amount: number, price: number }[] {
            const ids: { [key: string]: number } = {};

            this.selectedExtraDishes.forEach((dish: { id: number }) => {
                const dishId = dish.id;

                if (ids[dishId]) {
                    ids[dishId]++;
                } else {
                    ids[dishId] = 1;
                }
            });

            const result = Object.keys(ids).map((dishId) => {
                const count = ids[dishId];
                const dish = this.selectedExtraDishes.find(
                    (arr) => arr.id === parseInt(dishId)
                );
                const dishName = dish?.name || "Unknown";
                const dishPrice = dish?.price || 0
                return { dish: dishName, amount: count, price: dishPrice};
            });

            return result;
        },
        calculateTotalCost(price: number, option: string): void {
            if(option === "increment") {
                this.totalCosts += Number(price);
            }
            else {
                this.totalCosts -= Number(price);
            }
        },
        setExtras() {
            const nextButton = document.querySelector("#next_extra_button");
            if (nextButton && nextButton.classList.contains("disabled")) {
                return;
            }
            this.openExtraCart = false;
            this.$emit('setExtras', this.selectedExtraDrinks, this.selectedExtraDishes);
        },
        prevStep(): void {
            this.openExtraCart = false;
            this.$emit('prevStep')
        },
        toggleCart(): void {
            this.openExtraCart = !this.openExtraCart;
        },
        formattedPrice(price: number): string {
            let symbols = [
                '€',
                '$'
            ]

            const item = localStorage.getItem('currency_symbol');
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price}`;
                } else {
                    return `${price} ${item}`;
                }
            }
            return `${price}`;
        },
    },
    mounted() {
        this.getDrinks();
        this.getArrangements();
    }
})
