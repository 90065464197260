import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7e6f9cbb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "language-picker"
}
const _hoisted_2 = { class: "selected-option f-14" }
const _hoisted_3 = { class: "flag-container" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "center admin-title" }
const _hoisted_6 = {
  key: 0,
  class: "options shadow"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "option-wrapper" }
const _hoisted_9 = { class: "flag-container" }
const _hoisted_10 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_opacity_fade_in_component = _resolveComponent("opacity-fade-in-component")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["language-dropdown", _ctx.showOptions ? 'active' : '']),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
          style: _normalizeStyle({ borderColor: _ctx.showOptions ? 'var(--primary-admin-highlight)' : '', borderWidth: _ctx.showOptions ? '2px' : '' })
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: require(`@/assets/flags/flag_${_ctx.selectedOption.code}.png`),
                alt: _ctx.selectedOption.language
              }, null, 8, _hoisted_4)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", {
              class: _normalizeClass(["material-symbols-outlined symbol-size", { rotate: _ctx.showOptions, 'reverse-rotate': !_ctx.showOptions }])
            }, " expand_more", 2)
          ]),
          _createVNode(_component_opacity_fade_in_component, null, {
            default: _withCtx(() => [
              (_ctx.showOptions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "option",
                        key: index,
                        onClick: ($event: any) => (_ctx.selectOption(option))
                      }, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("img", {
                              src: require(`@/assets/flags/flag_${option.code}.png`),
                              alt: option.language
                            }, null, 8, _hoisted_10)
                          ]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t(option.language)), 1)
                        ])
                      ], 8, _hoisted_7))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ], 6)
      ]))
    : _createCommentVNode("", true)
}