import http from '@/utils/http-common';
import { NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router';

export async function checkAuth(to: RouteLocationNormalizedLoaded, from: RouteLocationNormalized, next: NavigationGuardNext) {
    try {
        const response = await http.get('/user');
        if (response.data) {
            next();
        } else {
            next('/login');
        }
    } catch (error) {
        console.error('Error checking authentication:', error);
        next('/login');
    }
}