
import { defineComponent } from 'vue'
import OpacityFadeInComponent from '@/components/Transitions/OpacityFadeInComponent.vue';

interface ILanguageOption {
    language: string,
    code: string,
}

export default defineComponent({
    name: "LanguageDropdown",
    components: {OpacityFadeInComponent},
    data() {
        return {
            loaded: false as boolean,
            selectedOption: {} as ILanguageOption,
            showOptions: false as boolean,
            options: [
                {
                    language: 'LANGUAGE_ENGLISH',
                    code: "en"
                },

                {
                    language: 'LANGUAGE_DUTCH',
                    code: "nl"
                },
            ] as ILanguageOption[]
        };
    },
    methods: {
        toggleDropdown() {
            this.showOptions = !this.showOptions;
        },
        selectOption(option: ILanguageOption) {
            this.selectedOption = option;
            this.changeLanguage(option.code)
        },
        handleClickOutside(event: Event) {
            if (!this.$el.contains(event.target)) {
                this.showOptions = false;
            }
        },
        changeLanguage(code: string) {
            const i18n = this.$i18n;
            i18n.locale = code;
        }
    },
    mounted(): void {
        const currentLocale = this.$i18n.locale;
        const matchedOption = this.options.find(option => option.code === currentLocale);
        if (matchedOption) {
            this.selectedOption = matchedOption;
        } else {
            this.selectedOption = this.options[0];
        }
        document.addEventListener('click', this.handleClickOutside);

        this.loaded = true;
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
})
