
//* Components */
import { defineComponent } from "vue";
//* Packages */
import http from "../../../utils/http-common";
//* Models */
import IArrangement from "../../../interfaces/IArrangement";
import IDish from "../../../interfaces/IDish";
import { toast } from 'vue3-toastify';

export default defineComponent({
    name: "ArrangementSelector",
    data() {
        return {
            size: null as unknown as number,
            arrangementList: [] as IArrangement[],
            selectedArrangements: [] as IArrangement[],
            openArrangementCart: false as boolean,
            totalCosts: 0 as number,
            isDirty: false as boolean,
        };
    },
    methods: {
        getArrangements() {
            http.get(`arrangements`, { params: { pagination: false } }).then((res) => {
                this.arrangementList = res.data;
            })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                });
        },
        addArrangement(arrangement: IArrangement) {
            if (this.selectedArrangements.length < this.size) {
                this.selectedArrangements.push(arrangement);
                this.animateIndicator();
                this.calculateTotalCost(arrangement.price, 'increment');
                if(this.isDirty) {
                    this.$emit('arrangementChange', this.selectedArrangements);
                }
            }
            else {
                toast(this.$t('ARRANGEMENT_SELECTOR_NO_MORE_ARRANGEMENT'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })
            }
        },
        removeArrangement(arrangement: IArrangement) {
            const indexToRemove = this.selectedArrangements.findIndex(
                (item) => item.id === arrangement.id
            );

            if (indexToRemove !== -1) {
                this.selectedArrangements.splice(indexToRemove, 1);
                this.animateIndicator();
                this.calculateTotalCost(arrangement.price, 'subtract');
                if(this.isDirty) {
                    this.$emit('arrangementChange', this.selectedArrangements);
                }
            }
        },
        countArrangement(id: number): number {
            return this.selectedArrangements.filter(
                (arrangement) => arrangement.id === id
            ).length;
        },
        animateIndicator(): void {
            const indicator = document.querySelector(
                "#arrangement-indicator"
            ) as HTMLElement;

            if (indicator) {
                indicator.classList.remove("grow-shrink");
                void indicator.offsetWidth;
                indicator.classList.add("grow-shrink");
            }
        },
        nextStep() {
            if(this.selectedArrangements.length != this.size && (this.size - this.selectedArrangements.length) > 0) {
                const difference = this.size - this.selectedArrangements.length;
                toast(this.$t(difference !== 1 ? 'ERROR_ARRANGEMENT_SELECTOR_ALL_ARRANGEMENTS' : 'ERROR_ARRANGEMENT_SELECTOR_ALL_ARRANGEMENT', {amount: difference}), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })

                return;
            }
            if(this.selectedArrangements.length != this.size && (this.size - this.selectedArrangements.length) < 0) {
                toast(this.$t('PAYMENT_DETAILS_TO_MUCH_ARRANGEMENTS'), {
                    type: 'error',
                    position: 'top-right',
                    dangerouslyHTMLString: true,
                    autoClose: 3000
                })

                return;
            }

            this.openArrangementCart = false;
            this.$emit('setArrangements', this.selectedArrangements);
            this.isDirty = true;
        },
        currentArrangementOverview(): { arrangement: string, amount: number, price: number }[] {
            const ids: { [key: string]: number } = {};

            this.selectedArrangements.forEach((arrangement: { id: number }) => {
                const arrangementId = arrangement.id;

                if (ids[arrangementId]) {
                    ids[arrangementId]++;
                } else {
                    ids[arrangementId] = 1;
                }
            });

            const result = Object.keys(ids).map((arrangementId) => {
                const count = ids[arrangementId];
                const arrangement = this.selectedArrangements.find(
                    (arr) => arr.id === parseInt(arrangementId)
                );
                const arrangementName = arrangement?.title || "Unknown";
                const arrangementPrice = arrangement?.price || 0;
                return { arrangement: arrangementName, amount: count, price: arrangementPrice };
            });

            return result;
        },
        prevStep(): void {
            this.openArrangementCart = false;
            this.$emit('prevStep')
        },
        toggleCart(): void {
            this.openArrangementCart = !this.openArrangementCart;
        },
        calculateTotalCost(price: number, option: string): void {
            if(option === "increment") {
                this.totalCosts += Number(price);
            }
            else {
                this.totalCosts -= Number(price);
            }
        },
        formattedPrice(price: number): string {
            let symbols = [
                '€',
                '$'
            ]

            const item = localStorage.getItem('currency_symbol');
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price}`;
                } else {
                    return `${price} ${item}`;
                }
            }
            return `${price}`;
        },
    },
    props: {
        guestAmount: Number,
    },
    computed: {
        groupedDishes(): (arrangement: IArrangement) => Record<string, IDish[]> {
            return (arrangement) => {
                const grouped: Record<string, IDish[]> = {};
                arrangement.dishes.forEach((dish) => {
                    if (!grouped[dish.category]) {
                        grouped[dish.category] = [];
                    }
                    grouped[dish.category].push(dish);
                });
                return grouped;
            };
        },
    },
    watch: {
        guestAmount: {
            handler(size) {
                this.size = size;
                this.getArrangements();
            },
        },
    },
    mounted() {
        //do
    }
});
