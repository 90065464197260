import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Vue3Toastify from 'vue3-toastify';
import { createI18n } from 'vue-i18n';
import 'vue3-toastify/dist/index.css';


import en from './i18n/languages/en/en.json';
import nl from './i18n/languages/nl/nl.json';

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        nl
    }
})


const app = createApp(App);
app.use(router);
app.use(Vue3Toastify);
app.use(i18n);

app.mount('#app');

