import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4d336495"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-dropdown modal-form-item" }
const _hoisted_2 = { class: "selected-option f-14" }
const _hoisted_3 = { class: "center p-tb-12 admin-title" }
const _hoisted_4 = {
  key: 0,
  class: "options-wrapper"
}
const _hoisted_5 = { class: "options" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown", [_ctx.showOptions ? 'active' : '', { disabled: !_ctx.category }]]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
      style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor, zIndex: _ctx.zIndex, borderColor: _ctx.showOptions ? 'var(--primary-admin-highlight)' : '', borderWidth: _ctx.showOptions ? '2px' : '', })
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(!_ctx.selectedOption
                        ? _ctx.$t('CAPACITY_DROPDOWN_SELECT_CAPACITY')
                        : _ctx.$t('CAPACITY_DROPDOWN_SELECTED', {capacity: _ctx.selectedOption})), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", {
          class: _normalizeClass(["material-symbols-outlined f-26", { rotate: _ctx.showOptions, 'reverse-rotate': !_ctx.showOptions }])
        }, " expand_more", 2)
      ]),
      (_ctx.showOptions)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "option",
                  key: option,
                  onClick: ($event: any) => (_ctx.selectOption(option))
                }, _toDisplayString(`${_ctx.formattedPrice(option)} - ${option} ${ _ctx.$t('GUESTS') }`), 9, _hoisted_6))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ], 6)
  ]))
}